"use client";
import dynamic from "next/dynamic";
import { useState, useEffect, useRef } from "react";
import { SpotifyData } from "@/types";
import { useInView } from "@/hooks/useFramerMotion";

const SpotifyProvider = dynamic(
  () => import("@/contexts/SpotifyContext").then((mod) => mod.SpotifyProvider),
  {
    ssr: false,
  }
);

const Spotify = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef(null);
  const loaded = useRef(false);
  const isInView = useInView(ref, { once: true, amount: "some" });
  const [data, setData] = useState<SpotifyData>();

  useEffect(() => {
    if (!isInView || loaded.current) return;
    async function fetchData() {
      try {
        const response = await fetch("/api/spotify", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({ id: 4 }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
        loaded.current = true;
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }
    if ("requestIdleCallback" in window) {
      requestIdleCallback(fetchData);
    } else {
      setTimeout(fetchData, 1);
    }
  }, [isInView]);

  return (
    <>
      <div
        ref={ref}
        id="spotify">
        {data && <SpotifyProvider data={data}>{children}</SpotifyProvider>}
      </div>
    </>
  );
};

export default Spotify;
