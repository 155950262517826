"use client";
import { useState, useLayoutEffect, ReactNode } from "react";
import { usePathname } from "@/i18n/routing";
import useFramerMotion, {
  LazyMotion,
  m,
  useScroll,
  useSpring,
  type MotionValuePatch as MotionValue,
} from "@/hooks/useFramerMotion";

// const PureNav = memo(Nav);
// const PureMassifLogo = memo(MassifLogo);

const HeaderWrap = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const framerMotionFeatures = useFramerMotion();
  const { scrollYProgress, scrollY } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 20,
  }) as MotionValue<number>;
  const [isScrolled, setIsScrolled] = useState(false);

  useLayoutEffect(() => {
    setIsScrolled(window.scrollY > 80);
  }, []);
  useLayoutEffect(() => {
    // setIsScrolled(false);
    scaleX.set(0);
  }, [pathname, scaleX]);

  if (typeof window !== "undefined") {
    scrollY.on("change", () => {
      if (scrollY.get() > 80) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }

  return (
    <LazyMotion features={framerMotionFeatures}>
      <m.header
        role="banner"
        layoutScroll
        className={`section-p fixed left-0 top-0 z-50 flex w-full items-center justify-between gap-8 transition-all duration-500 ~py-4/12 ${
          isScrolled ? "!~py-4/6" : ""
        }`}>
        {children}
        <m.div
          style={{ scaleX: scaleX }}
          className="absolute inset-0 bottom-auto h-0.5 origin-left bg-accent"
        />
        <div
          className={`absolute inset-0 -z-10 transition-opacity duration-500 ${
            isScrolled ? "" : "opacity-0"
          }`}>
          <div
            className={`absolute inset-0 bg-gradient-to-b from-dark via-dark/75 via-30% to-dark/0 backdrop-blur-[6px] [mask:linear-gradient(rgb(0,_0,_0)_75%,_rgba(0,_0,_0,_0)_100%)]`}
          />
          <div
            className={`absolute inset-0 bg-transparent bg-[length:4px_4px] [background-image:radial-gradient(rgba(0,_0,_0,_0)_1px,_var(--clr-primary-dark)_1px)] [mask:linear-gradient(rgb(0,_0,_0)_7%,_rgba(0,_0,_0,_0)_100%)]`}
          />
        </div>
      </m.header>
    </LazyMotion>
  );
};

export default HeaderWrap;
