"use client";
import dynamic from "next/dynamic";
import { useState, useRef, ReactNode } from "react";
import { LinkButton } from "@/components/ui/Button";
import { LottieProps } from "react-lottie-player/dist/LottiePlayerLight";
import { isIOS } from "react-device-detect";
import { useTranslations } from "next-intl";
import { useSceneContext } from "@/contexts/SceneContext";

const LottiePlayer = dynamic(() => import("@/components/ui/LottiePlayer"), {
  ssr: false,
});

const GetInTouchButton = ({
  href = "",
  blank = true,
  children,
  className = "",
}: {
  href?: string;
  blank?: boolean;
  children?: ReactNode;
  className?: string;
}) => {
  const { isFooterContactHovered } = useSceneContext();
  const t = useTranslations("common");
  const ref = useRef<HTMLAnchorElement>(null);
  const label = children ? (children as ReactNode) : t("sendMessage");
  const [lottiePlay, setLottiePlay] = useState(false);
  const [lottieDirection, setLottieDirection] =
    useState<LottieProps["direction"]>(1);
  const [lottieGoTo, setLottieGoTo] = useState(0);
  const complete = () => {
    setLottieDirection(-1);
    setLottieGoTo(0);
    setLottiePlay(false);
    if (ref.current) window.open(ref.current.href, "_self");
  };
  const play = () => {
    setLottieDirection(1);
    setLottiePlay(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isIOS) event.preventDefault();
    play();
  };

  return (
    <div className={`${className} relative`}>
      <LinkButton
        onMouseEnter={() => (isFooterContactHovered.current = true)}
        onMouseLeave={() => (isFooterContactHovered.current = false)}
        variant="fancy"
        ref={ref}
        href={href ? href : "mailto:studio@massif.ch"}
        target={blank ? "_blank" : "_self"}
        onClick={(event) => handleClick(event)}
        className="relative">
        {label}
        <div
          className={
            "pointer-events-none absolute left-1/2 top-1/2 aspect-video w-[200%] -translate-x-1/2 -translate-y-1/2"
          }></div>
      </LinkButton>
      {lottiePlay && (
        <LottiePlayer
          aria-hidden
          className="absolute -inset-10"
          src="/lottie-animations/confetti.json"
          play={lottiePlay}
          direction={lottieDirection}
          goTo={lottieGoTo}
          onComplete={() => complete()}
        />
      )}
    </div>
  );
};
export default GetInTouchButton;
