"use client";
import dynamic from "next/dynamic";

const FooterMountain = dynamic(
  () => import("@/components/3D/Scenes/FooterMountain"),
  {
    ssr: false,
  }
);

const FooterGfx = () => {
  return (
    <>
      <div className="absolute bottom-0 left-0 -z-10 h-20 w-screen bg-gradient-to-t from-[#3a1e33] to-[#3a1e33]/0 md:h-44 xl:h-72" />
      <div className="absolute -bottom-0 left-1/2 z-0 aspect-[16/9] w-[125%] max-w-7xl translate-x-[-30%] md:-bottom-10 md:w-full md:translate-x-[-30%]">
        <FooterMountain />
      </div>
    </>
  );
};

FooterGfx.displayName = "FooterGfx";

export default FooterGfx;
