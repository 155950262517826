"use client";
import useFramerMotion, {
  AnimatePresence,
  LazyMotion,
  m,
} from "@/hooks/useFramerMotion";
import { cn } from "@/utils";
import MarkDown from "@/components/MarkDown";

const parentVariant = {
  initial: {
    opacity: 0.001,
  },
  animate: {
    opacity: 1,

    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.12,
    },
  },
};

const fadeinAnimationVariants = {
  initial: {
    y: 30,
    skewY: 5,
    opacity: 0.001,
    transformOrigin: "0 50%",
  },
  animate: {
    y: 0,
    skewY: 0,
    opacity: 1,
  },
};

type TextGenerateEffectProps = {
  children: string;
  className?: string;
  wordClassName?: string;
};

const TextGenerateEffect = ({
  children,
  className,
  wordClassName,
}: TextGenerateEffectProps) => {
  const framerMotionFeatures = useFramerMotion();
  const lines = children.split("\\");

  const renderWords = () => {
    return (
      <LazyMotion features={framerMotionFeatures}>
        <AnimatePresence>
          <m.div
            key={`text-${children}`}
            variants={parentVariant}
            initial="initial"
            whileInView="animate"
            viewport={{ amount: "some", once: true }}
            className="flex flex-col will-change-[opacity]">
            {lines.map((line, index) => {
              const wordsArray = line.split(" ").filter((word) => word); // Split the line into words
              return (
                <div
                  key={`line-${index}`}
                  className="line">
                  <AnimatePresence>
                    {wordsArray.map((word, idx) => (
                      <m.span
                        key={word + idx}
                        animate={{
                          overflow: "visible",
                        }}
                        transition={{ delay: 0.8 }}
                        className="relative overflow-hidden">
                        <m.span
                          className={cn(
                            "word inline-block will-change-[opacity,transform]",
                            wordClassName
                          )}
                          variants={fadeinAnimationVariants}
                          transition={{
                            type: "spring",
                          }}>
                          <MarkDown skipParagraph>{word + "&nbsp;"}</MarkDown>
                        </m.span>
                      </m.span>
                    ))}
                  </AnimatePresence>
                </div>
              );
            })}
          </m.div>
        </AnimatePresence>
      </LazyMotion>
    );
  };

  return <div className={cn(className)}>{renderWords()}</div>;
};

export default TextGenerateEffect;
