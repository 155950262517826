import {
  // m,
  LazyMotion,
  AnimatePresence,
  animate,
  useScroll,
  useSpring,
  useVelocity,
  useInView,
  useMotionValue,
  MotionValue,
  useTransform,
  useAnimate,
  useAnimationFrame,
  frame,
  useTime,
  animationControls,
  LayoutGroup,
} from "motion/react";
// import { useAnimate } from "motion/react-mini";
import * as m from "motion/react-m";
import { motion as m3d } from "framer-motion-3d";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MotionValuePatch<T> = MotionValue<T> & any;

const useFramerMotion = () => {
  return () =>
    import("@/utils/framerMotionFeatures").then((res) => res.default);
};

export default useFramerMotion;
export {
  LazyMotion,
  m,
  m3d,
  AnimatePresence,
  animate,
  useAnimate,
  useScroll,
  useSpring,
  useVelocity,
  useMotionValue,
  useTransform,
  useAnimationFrame,
  useInView,
  frame,
  useTime,
  LayoutGroup,
};

export type { animationControls, MotionValue, MotionValuePatch };
