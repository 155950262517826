import Markdown from "react-markdown";
import { host } from "@/config";

type Props = {
  children: string;
  skipParagraph?: boolean;
};

const MarkDown = ({ skipParagraph = false, children, ...props }: Props) => {
  return (
    <Markdown
      components={{
        p: ({ ...props }) =>
          skipParagraph ? <>{props.children}</> : <p>{props.children}</p>,
        u: ({ ...props }) => <u>{props.children}</u>,
        a: ({ children, ...props }) => {
          try {
            const url = new URL(props.href ?? "", host);
            if (url.origin !== host) {
              props.target = "_blank";
              props.rel = "noopener noreferrer";
            }
            return <a {...props}>{children}</a>;
          } catch {
            return <>{children}</>;
          }
        },
      }}
      {...props}>
      {children}
    </Markdown>
  );
};

export default MarkDown;
