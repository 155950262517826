"use client";
import dynamic from "next/dynamic";
import { TransitionRouter } from "next-transition-router";
import { useAnimate } from "@/hooks/useFramerMotion";
import { useRef, useEffect } from "react";

const Scene = dynamic(() => import("@/components/3D/Scene"), {
  ssr: false,
});
const Stars = dynamic(() => import("@/components/Stars"), {
  ssr: false,
});

export function TransitionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // const framerMotionFeatures = useFramerMotion();
  const [scope, animateScope] = useAnimate();
  const footer = useRef<HTMLElement>(null!);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  let lastHeight = 0;
  let transformCenterY = 0;

  useEffect(() => {
    const footerElement = document.getElementById("footer-inner");
    if (footerElement) footer.current = footerElement;
  }, []);

  return (
    <TransitionRouter
      auto
      leave={async (next) => {
        lastHeight = scope.current.clientHeight;
        scope.current.style.height = `${lastHeight}px`;
        transformCenterY = window.innerHeight / 2 + window.scrollY;
        if (footer.current) {
          animateScope(
            footer.current,
            {
              opacity: 0,
              scale: 1.5,
            },
            { duration: 0.4, ease: "easeInOut" }
          );
        }
        await animateScope(
          scope.current,
          {
            opacity: 0,
            transformOrigin: `50% ${transformCenterY}px`,
            scale: 1.5,
          },
          { duration: 0.4, ease: "easeInOut" }
        );
        next();
      }}
      enter={async (next) => {
        transformCenterY = window.innerHeight / 2;
        // scope.current.style.opacity = 1;
        await animateScope(
          scope.current,
          {
            opacity: 1,
            transformOrigin: `50% ${transformCenterY}px`,
            scale: 1,
          },
          { duration: 0.4, ease: "easeInOut" }
        );
        // scope.current.style.height = "";

        if (footer.current) {
          animateScope(
            footer.current,
            {
              opacity: 1,
              scale: 1,
            },
            { duration: 0.4, ease: "easeInOut" }
          );
        }

        next();
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          scope.current.style = "";
          if (footer.current) {
            footer.current.style.transform = "";
          }
        }, 50);
      }}>
      <div
        ref={scope}
        className="will-change-auto">
        {children}
      </div>
      <Scene />
      <Stars />
    </TransitionRouter>
  );
}
