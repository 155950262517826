import(/* webpackMode: "eager", webpackExports: ["domMax"] */ "__barrel_optimize__?names=domMax!=!/vercel/path0/node_modules/.pnpm/motion@12.6.2_@emotion+is-prop-valid@1.2.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/motion/dist/es/motion/lib/react.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.5_next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.5_next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.5_next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/components/BaseLayout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/Inter.woff2\",\"variable\":\"--font-inter\",\"preload\":true,\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.26_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/components/BaseLayout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/JetBrains_Mono.woff2\",\"variable\":\"--font-jetbrains-mono\",\"preload\":true,\"display\":\"swap\"}],\"variableName\":\"jetBrainsMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/opengraph.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/twitter.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/FooterGfx.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Footer/FooterHeading.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/FooterSpecial.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/FooterSpotify.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Header/HeaderWrap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Header/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YearsCounter"] */ "/vercel/path0/src/components/Hero/YearsCounter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Spotify.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/GetInTouchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/TextGenerateEffect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/ToolTip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SceneContextProvider"] */ "/vercel/path0/src/contexts/SceneContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TransitionProvider"] */ "/vercel/path0/src/contexts/transitionProvider.tsx");
