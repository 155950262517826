"use client";
import Image from "next/image";
import { usePathname } from "@/i18n/routing";
import { useEffect, useState } from "react";
import TextGenerateEffect from "@/components/ui/TextGenerateEffect";
import useFramerMotion, { LazyMotion, m } from "@/hooks/useFramerMotion";
import { items } from "@/data/FooterHeadings";
import StudioMicrophone from "/public/assets/studio-microphone.png";

const FooterHeading = () => {
  const pathname = usePathname();
  const framerMotionFeatures = useFramerMotion();
  const [heading, setHeading] = useState<string>("");
  useEffect(() => {
    const heading = `${items[Math.floor(Math.random() * items.length)]}`;
    setHeading(heading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, items]);
  return (
    <LazyMotion features={framerMotionFeatures}>
      <h3 className="[&_em]:text-outline-shadow relative z-10 -skew-y-3 text-balance font-bold ~text-[1.625rem]/6xl ~leading-[2.25rem]/[4.25rem] [&_em]:not-italic [&_em]:text-dark">
        {heading && (
          <>
            <span className="text-outline-shadow absolute left-0 font-serif ~-top-0/1">
              <Image
                width={200}
                height={200}
                sizes={"(min-width: 768px) 80px, 36px"}
                className="~w-9/20"
                src={StudioMicrophone}
                alt="Studio Microphone"
              />
            </span>
            <TextGenerateEffect className="[&_.line:first-child>span:first-child]:indent-[2.15ch]">
              {heading}
            </TextGenerateEffect>
            <m.span
              key={heading + "line-1"}
              initial={{
                scaleX: 0,
                transformOrigin: "left center",
              }}
              whileInView={{
                scaleX: 1,
              }}
              transition={{ delay: 0.5 }}
              viewport={{ amount: "some" }}
              className="absolute bottom-[calc(100%+2.5rem)] z-10 w-full border-t-[3px] border-mint drop-shadow-[0_0_3px_var(--clr-mint)] [mask:linear-gradient(to_right,_rgba(0,_0,_0,_0),_rgb(0,_0,_0)_15%,_rgb(0,_0,_0)_33%,_rgba(0,_0,_0,_0)_100%)] lg:w-11/12"
            />
            <m.span
              key={heading + "line-2"}
              initial={{
                scaleX: 0,
                transformOrigin: "left center",
              }}
              whileInView={{
                scaleX: 1,
              }}
              transition={{ delay: 0.75 }}
              viewport={{ amount: "some" }}
              className="absolute bottom-[calc(100%+2rem)] z-10 w-full border-t border-mint drop-shadow-[0_0_2px_var(--clr-mint)] ~-left-5/12 [mask:linear-gradient(to_right,_rgba(0,_0,_0,_0),_rgb(0,_0,_0)_15%,_rgb(0,_0,_0)_33%,_rgba(0,_0,_0,_0)_100%)] lg:w-10/12"
            />
          </>
        )}
      </h3>
    </LazyMotion>
  );
};

export default FooterHeading;
