export const items = [
  "This is _ground_ _control_ to _Major_ _DOM_, you've really made _the_ _grade_",
  "You down with _HTTP_? \\Yeah, you know _me_!",
  "Just a _small-town_ dev, \\coding in the local _.env_",
  "I'm a _web_ _developer_, \\_not_ a web designer",
  "Hello from the _other_ _site_, \\I must've _pinged_ yours \\a thousand times ...",
  "_Whatever_ I said, _whatever_ I did, I didn't mean it, I just want to _make_ _it_ _good_",
  "_Wake_ _me_ up, before \\you _404_...",
  "If there's _something_ _strange_ in your HTML, who _you_ _gonna_ _call_?",
  "Cause _maybe_, \\your page's gonna \\_be_ _the_ _one_ that saves me",
  "Hello, is it _clean_ _code_ \\you're _looking_ _for_?",
  "Every _move_ you _make_, \\every _tag_ you _break_, \\I'll be _testing_ you",
  "_Say_ my name, \\_say_ my name, when the _bugs_ _surround_ you",
  "Why'd you have to _go_ \\and _make_ things so _complicated_?",
  "It's the _final_ mousedown, \\da-na-na-_na_, da-na-na-_naaa_ ...",
  "Hit the _code_ _jack_, and don't you _go_ _back_ no more",
  "Hey _dude_! Don't _make_ it _bad_, take a _bad_ _site_ \\and _make_ it _better_",
  "I'm a _coder_ in a _bottle_ \\_baby_, you _gotta_ _rub_ me \\the _right_ _way_",
  "It was all a _dream_, \\I used to _code_ in _HTML_ _schemes_ ...",
  "Aight, _stop_! _Collaborate_ and _listen_, your _dev's_ back with a _brand-new_ invention",
  "If you're having _code_ _problems_ I feel _bad_ for you son, I got _99_ _problems_ but _my_ _code_ ain't one",
  "They see me _codin'_, they _love_ it, tryna _catch_ me _writing_ _dirty_",
  "You can _check_ your code _out_ any _time_ you _like_, but you'll _never_ want to _leave_",
  "I like _big_ _buttons_ \\and I _cannot_ lie!",
  "I was like _goodness_ gracious, this _code_ be _bodacious_",
  "But I gotta _test_ _twice_, \\_before_ I push my _code_ away, I know _all_ _the_ _bugs_ in play, cause _I_ _wrote_ _them_ too",
  "I wanna _code_ for \\_somebody_, I wanna _feel_ \\the _heat_ with _somebody_",
  "To make the _web_ a _better_ place, _take_ _a_ _look_ at your code and make a _change_",
  "I _built_ _a_ _site_ for you \\oh _what_ _a_ _thing_ to do \\and it was _all_ _yellow_",
  "I wrote _a_ _line_ \\I wrote a line _for_ _you_ ...",
  "_Save_ _tonight_, deploy the site at dawn, _come_ _tomorrow_, _your_ _project_ will _be_ _done_",
  "Cause _every_ little _site_, \\is gonna be _alright_",
  "Oh, _sweet_ _site_ of mine ... oh, sweet _tech-stack_ of mine",
  "In the middle of _the_ _night_, I _go_ _coding_ in my sleep, \\from _the_ _mountain_ of bugs, \\to the _river_ so deep",
  "Every _push_ you make, \\every _thing_ you break, \\I'll be _testing_ _you_",
  "Ohhh repo, _you_ _pulled_ \\and _you_ _merged_ without breaking",
  "_Stack_ _overflow_ is the kinda \\place to _raise_ _your_ _skills_",
];
