"use client";
import dynamic from "next/dynamic";
const FooterSpotifySimple = dynamic(
  () => import("@/components/Footer/FooterSpotifySimple"),
  { ssr: false }
);
const FooterSpotify = () => {
  return <FooterSpotifySimple />;
};

FooterSpotify.displayName = "FooterSpotify";

export default FooterSpotify;
