import { Link as LinkImpl, Pathnames } from "@/i18n/routing";
import {
  ReactNode,
  MouseEventHandler,
  forwardRef,
  MutableRefObject,
} from "react";

export type LinkProps = {
  href: string | Pathnames | { pathname: Pathnames; hash?: string };
  title?: string;
  locale?: string;
  className?: string;
  target?: string;
  rel?: string;
  prefetch?: boolean;
  tabindex?: number;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement> | undefined;
  onMouseLeave?: MouseEventHandler<HTMLAnchorElement> | undefined;
};

export const Link = forwardRef(
  (
    {
      href,
      children,
      ...props
    }: {
      children: ReactNode;
    } & LinkProps,
    fref
  ) => {
    return (
      <LinkImpl
        ref={fref as MutableRefObject<HTMLAnchorElement>}
        href={href as Pathnames}
        prefetch={false}
        {...props}>
        {children}
      </LinkImpl>
    );
  }
);

Link.displayName = "Link";
