"use client";
import { useEffect, useState, useRef } from "react";
import dynamic from "next/dynamic";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { useInView } from "@/hooks/useFramerMotion";

const FooterVisual = dynamic(
  () => import("@/components/3D/Scenes/FooterVisual"),
  {
    ssr: false,
  }
);
const FooterPlanet = dynamic(
  () => import("@/components/3D/Scenes/FooterPlanet"),
  {
    ssr: false,
  }
);
const LottiePlayer = dynamic(() => import("@/components/ui/LottiePlayer"), {
  ssr: false,
});

const FooterSpecial = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: "some" });

  const { md } = useBreakpoint();
  const [showPlanet, setShowPlanet] = useState<boolean>(false);

  useEffect(() => {
    if (md) {
      setShowPlanet(true);
    } else {
      setShowPlanet(false);
    }
  }, [md]);

  return (
    <>
      <div
        ref={ref}
        className="max-md:order-first md:~translate-x-[2rem]/[6rem] md:~translate-y-[-2rem]/[-12rem]">
        {showPlanet && isInView && (
          <div className="relative flex justify-center py-16">
            <div className="relative">
              <div className="relative z-10 -skew-y-3">
                <div className="scale-y-125 tracking-widest">
                  <div className="flex justify-center gap-3 pb-1 text-center text-sm uppercase">
                    <span className="drop-shadow-[0_0_2px_rgba(255,255,255,0.5)]">
                      Made with that <strong>cosmic</strong>
                    </span>
                    <span className="relative scale-x-125">🚀</span>
                  </div>
                  <div className="relative pb-1 text-center text-7xl font-black uppercase before:absolute before:left-0 before:top-0 before:w-full before:border-t-[3px] before:border-white before:drop-shadow-[0_0_2px_rgba(255,255,255,0.5)] before:[border-top-style:double] before:[mask:linear-gradient(to_right,_rgba(0,_0,_0,_0)_0%,_rgb(0,_0,_0)_25%,_rgb(0,_0,_0)_75%,_rgba(0,_0,_0,_0)_100%)] after:absolute after:bottom-0 after:left-0 after:w-full after:border-b-[5px] after:border-white after:drop-shadow-[0_0_3px_rgba(255,255,255,0.5)] after:[mask:linear-gradient(to_right,_rgba(0,_0,_0,_0)_0%,_rgb(0,_0,_0)_10%,_rgb(0,_0,_0)_90%,_rgba(0,_0,_0,_0)_100%)]">
                    <span className="drop-shadow-[0_0_7px_rgba(255,255,255,0.5)]">
                      MASSIF
                    </span>
                  </div>
                  <div className="relative flex items-center justify-center gap-3 py-1 text-center font-bold uppercase">
                    <div className="absolute inset-0 -scale-y-75">
                      <FooterVisual />
                    </div>
                    <span className="relative pb-1 text-2xl tracking-wide after:absolute after:left-0 after:top-full after:w-full after:border-b-4 after:border-accent after:drop-shadow-[0_0_3px_var(--clr-accent-400)] after:[mask:linear-gradient(to_right,_rgba(0,_0,_0,_0)_0%,_rgb(0,_0,_0)_25%,_rgb(0,_0,_0)_75%,_rgba(0,_0,_0,_0)_100%)]">
                      <span className="drop-shadow-[0_0_3px_rgba(255,255,255,0.5)]">
                        Salsa especial
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <LottiePlayer
                aria-hidden
                className="absolute -inset-32 -skew-y-3"
                src="/lottie-animations/space-orbit-wobble.json"
                play
                loop
                speed={0.2}
                rendererSettings={{
                  preserveAspectRatio: "none slice",
                }}
              />
            </div>
            <div className="absolute -inset-32">
              <FooterPlanet />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FooterSpecial;
