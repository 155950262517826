import { useMediaQuery } from "usehooks-ts";

export const useBreakpoint = () => {
  // if (typeof window === "undefined")
  //   return {
  //     sm: false,
  //     maxSm: false,
  //     md: false,
  //     maxMd: false,
  //     lg: false,
  //     maxLg: false,
  //     xl: false,
  //     maxXl: false,
  //     xxl: false,
  //     maxXxl: false,
  //   };

  // @media /* 392px */)
  const xs = useMediaQuery("(min-width: 24.5rem)");
  const maxXs = useMediaQuery("(max-width: 24.5rem)");
  // @media /* 480px */)
  const sm = useMediaQuery("(min-width: 30rem)");
  const maxSm = useMediaQuery("(max-width: 30rem)");
  // @media /* 768px */)
  const md = useMediaQuery("(min-width: 48rem)");
  const maxMd = useMediaQuery("(max-width: 48rem)");
  // @media /* 1024px */)
  const lg = useMediaQuery("(min-width: 64rem)");
  const maxLg = useMediaQuery("(max-width: 64rem)");
  // @media /* 1280px */)
  const xl = useMediaQuery("(min-width: 80rem)");
  const maxXl = useMediaQuery("(max-width: 80rem)");
  // @media /* 1536px */)
  const xxl = useMediaQuery("(min-width: 96rem)");
  const maxXxl = useMediaQuery("(max-width: 96rem)");
  return {
    xs,
    maxXs,
    sm,
    maxSm,
    md,
    maxMd,
    lg,
    maxLg,
    xl,
    maxXl,
    xxl,
    maxXxl,
  };
};
