import { defineRouting } from "next-intl/routing";
import { createNavigation } from "next-intl/navigation";

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: ["de", "en"],

  // Used when no locale matches
  defaultLocale: "de",
  // localePrefix: 'never',
  pathnames: {
    "/": "/",
    "/clients": {
      en: "/clients",
      de: "/kundschaft",
    },
    "/about": {
      en: "/about",
      de: "/ueber",
    },
    "/contact": {
      en: "/contact",
      de: "/kontakt",
    },
    "/kudos": {
      en: "/kudos",
      de: "/anerkennungen",
    },
    "/privacy-policy": {
      en: "/privacy-policy",
      de: "/datenschutz",
    },
    "/imprint": {
      en: "/imprint",
      de: "/impressum",
    },
  },
});

export type Pathnames = keyof typeof routing.pathnames;
export type Locale = (typeof routing.locales)[number];
// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);
