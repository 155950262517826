import { useTransition } from "react";
import { useParams } from "next/navigation";
import { useLocale } from "next-intl";
import { routing, usePathname, useRouter } from "@/i18n/routing";
import useFramerMotion, { LazyMotion, m } from "@/hooks/useFramerMotion";

const LanguageSwitcher = () => {
  const framerMotionFeatures = useFramerMotion();
  const [isPending, startTransition] = useTransition();

  const locale = useLocale();
  const pathname = usePathname();
  const params = useParams();

  const locales = routing.locales;
  const router = useRouter();
  const changeLocale = (locale: string) => {
    startTransition(() => {
      router.replace(
        // @ts-expect-error -- TypeScript will validate that only known `params`
        // are used in combination with a given `pathname`. Since the two will
        // always match for the current route, we can skip runtime checks.
        { pathname, params },
        { locale }
      );
    });
  };
  return (
    <LazyMotion features={framerMotionFeatures}>
      <div className="flex gap-4">
        {locales.map((currentLocale) => {
          const isActive = locale === currentLocale;
          return (
            <button
              onClick={() => changeLocale(currentLocale)}
              type="button"
              key={currentLocale}
              className={`relative font-medium uppercase transition-all hover:text-accent ${
                isActive ? "text-accent" : ""
              } ${isPending ? "pointer-events-none opacity-30" : ""}`}>
              {currentLocale}
              {isActive && (
                <m.div
                  layoutId="activeItem"
                  transition={{
                    type: "spring",
                    bounce: 0.3,
                    duration: 0.6,
                  }}
                  className="absolute bottom-0.5 h-[2px] w-full bg-accent"
                />
              )}
            </button>
          );
        })}
      </div>
    </LazyMotion>
  );
};

export default LanguageSwitcher;
