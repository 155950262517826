import { forwardRef, ButtonHTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Link, LinkProps } from "@/components/Link";
import { cn } from "@/utils";

const buttonVariants = cva(
  "group inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-full px-4 py-2 font-secondary font-semibold ring-offset-white transition-colors duration-300",
  {
    variants: {
      variant: {
        default:
          "text-outline-shadow-secondary relative overflow-hidden rounded-full border-2 border-mint bg-dark tracking-widest",
        primary:
          "text-outline-shadow-secondary relative overflow-hidden rounded-full border-2 border-dark bg-mint tracking-widest",
        outline:
          "border border-accent bg-transparent text-accent hover:bg-accent hover:text-dark",
        fancy:
          "relative inline-block overflow-clip rounded-full bg-dark p-[2px] font-semibold leading-6 text-primary no-underline shadow-xl shadow-dark/70 transition-all duration-700 hover:shadow-lg hover:shadow-dark/70",
        magic:
          "relative inline-flex overflow-hidden rounded-lg p-px focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-accent-950",
      },
      size: {
        default: "px-6 ~text-base/lg",
        md: "px-6 py-3 ~text-base/lg",
        lg: "px-8 py-3 text-base uppercase tracking-widest",
        fancy: "~text-sm/base",
        magic: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

type LinkButtonProps = LinkProps &
  VariantProps<typeof buttonVariants> & {
    children: React.ReactNode;
  };

const getOutput = ({
  variant,
  size,
  children,
}: VariantProps<typeof buttonVariants> & { children: React.ReactNode }) => {
  let output = null;
  switch (variant) {
    case "fancy":
      size = "fancy";
      output = (
        <>
          <span
            className={
              "absolute left-1/2 top-1/2 aspect-square w-full animate-bgCenter bg-[image:conic-gradient(var(--clr-cyan),var(--clr-magenta),var(--clr-yellow),var(--clr-cyan),var(--clr-magenta),var(--clr-yellow),var(--clr-cyan))] opacity-0 transition-opacity [transition-duration:700ms] group-hover:opacity-100"
            }
          />

          <div className="relative z-10 flex items-center rounded-full bg-dark px-6 py-2 ring-1 ring-white/10 transition-colors hover:bg-black">
            <span>{children}</span>
          </div>
          <span className="absolute left-1/2 top-1/2 aspect-square w-full animate-bgCenter bg-[image:conic-gradient(var(--clr-accent),var(--clr-accent-400),var(--clr-accent),var(--clr-accent-700),var(--clr-accent),var(--clr-accent-400),var(--clr-accent),var(--clr-accent-700),var(--clr-accent))] transition-opacity [transition-duration:700ms] group-hover:opacity-0" />
        </>
      );
      break;
    case "magic":
      size = "fancy";
      output = (
        <>
          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,var(--clr-accent-500)_0%,var(--clr-accent-200)_50%,var(--clr-accent-500)_100%)]" />
          <span className="z-10 inline-flex cursor-pointer items-center justify-center gap-4 rounded-lg bg-slate-950 text-base font-medium text-white backdrop-blur-3xl ~px-4/8 ~py-2/3">
            {children}
          </span>{" "}
        </>
      );
      break;
    case "primary":
      output = (
        <>
          <span className="absolute aspect-square w-0 rounded-full bg-accent transition-all duration-300 ease-out group-hover:w-[102%]"></span>
          <span className="absolute inset-0 -mt-0.5 size-full rounded-lg bg-gradient-to-b from-transparent via-transparent to-dark/70 opacity-15"></span>
          <span className="relative">{children}</span>
        </>
      );
      break;
    default:
      output = (
        <>
          <span className="absolute aspect-square w-0 rounded-full bg-mint transition-all duration-300 ease-out group-hover:w-[102%]"></span>
          <span className="absolute inset-0 -mt-1 size-full rounded-lg bg-gradient-to-b from-transparent via-transparent to-dark/70 opacity-20"></span>
          <span className="relative">{children}</span>
        </>
      );
      break;
  }
  return { output, size };
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    const children = props.children;
    const { output, size: newSize } = getOutput({ variant, size, children });
    return (
      <button
        className={cn(buttonVariants({ variant, size: newSize, className }))}
        ref={ref}
        {...props}>
        {output}
      </button>
    );
  }
);

Button.displayName = "Button";

const LinkButton = forwardRef(
  ({ href, className, variant, size, ...props }: LinkButtonProps, ref) => {
    const children = props.children;
    const { output, size: newSize } = getOutput({ variant, size, children });
    return (
      <Link
        href={href}
        className={cn(buttonVariants({ variant, size: newSize, className }))}
        ref={ref}
        {...props}>
        {output}
      </Link>
    );
  }
);

LinkButton.displayName = "LinkButton";

export { Button, LinkButton, buttonVariants };
