"use client";
import dynamic from "next/dynamic";
import { useState, useLayoutEffect } from "react";
import { useSelectedLayoutSegment } from "next/navigation";
import GetInTouchButton from "@/components/ui/GetInTouchButton";
import useFramerMotion, { LazyMotion, m } from "@/hooks/useFramerMotion";
import { Pathnames } from "@/i18n/routing";
import { Link } from "@/components/Link";
import { useTranslations, useLocale } from "next-intl";
import LanguageSwitcher from "./LanguageSwitcher";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import ChangeHologram from "../ui/ChangeHologram";

const NavMobile = dynamic(() => import("./NavMobile"));

export const routes: { path: Pathnames; key: string; hologram: number }[] = [
  {
    key: "portfolio",
    path: "/",
    hologram: 3,
  },
  {
    key: "clients",
    path: "/clients",
    hologram: 2,
  },
  {
    key: "about",
    path: "/about",
    hologram: 5,
  },
  {
    key: "contact",
    path: "/contact",
    hologram: 4,
  },
];

const Nav = () => {
  const { maxXl } = useBreakpoint();
  const framerMotionFeatures = useFramerMotion();
  const selectedLayoutSegment = useSelectedLayoutSegment();
  const pathname = selectedLayoutSegment ? `/${selectedLayoutSegment}` : "/";
  const [mobileNavVisible, setMobileNavVisible] = useState(true);

  const locale = useLocale();

  const t = useTranslations("nav");

  useLayoutEffect(() => {
    setMobileNavVisible(maxXl);
  }, [maxXl]);

  return (
    <>
      <LazyMotion features={framerMotionFeatures}>
        <div className="items-center gap-8 max-xl:hidden xl:flex">
          <nav
            role="navigation"
            className="items-center gap-8 xl:flex">
            {routes.map((item) => {
              const isActive = pathname === item.path;
              return (
                <ChangeHologram
                  hologram={item.hologram}
                  key={item.key}>
                  <Link
                    href={item.path}
                    locale={locale}
                    className={`${
                      isActive ? "text-accent" : ""
                    } relative font-medium tracking-wide transition-all hover:text-accent`}>
                    {t(item.key)}
                    {isActive && (
                      <m.div
                        layoutId="activeItem"
                        transition={{
                          type: "spring",
                          bounce: 0.3,
                          duration: 0.6,
                        }}
                        className="absolute -bottom-0.5 h-[2px] w-full bg-accent"
                      />
                    )}
                  </Link>
                </ChangeHologram>
              );
            })}
          </nav>
          <GetInTouchButton />
          <LanguageSwitcher />
        </div>
        {mobileNavVisible && <NavMobile />}
      </LazyMotion>
    </>
  );
};

export default Nav;
